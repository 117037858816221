import request from '$services/request';
import { apiv1 } from '$services/url';

// 获取员工列表
export const getApiEmployeeList = (params: any) => {
  return request<any>({
    url: apiv1.getEmployeeList,
    method: 'GET',
    params
  });
};

// 获取系统权限数据列表
export const getRoleList = () => {
  return request<any>({
    url: apiv1.getSysRoleList,
    method: 'GET',
    params: {
      page: 1,
      limit: 1000
    }
  });
};

// 获取擅长学科数据列表
export const getJdgroupList = () => {
  return request<any>({
    url: apiv1.getJdgroupList,
    method: 'GET',
    params: {
      page: 1,
      limit: 1000
    }
  });
};

// 创建员工
export const createEmp = (data: any) => {
  return request<any>({
    url: apiv1.addEmp,
    method: 'POST',
    data
  });
};

// 更新员工
export const updateEmp = (data: any) => {
  return request<any>({
    url: apiv1.updateEmp,
    method: 'POST',
    data
  });
};

// 删除员工
export const deleteEmp = (ids: number[]) => {
  return request<any>({
    url: apiv1.deleteEmp,
    method: 'POST',
    data: ids
  });
};

// 检查手机号是否重复
export const checkPhone = (data: { id: string, phone: string }) => {
  return request<any>({
    url: apiv1.checkMobile,
    method: 'POST',
    data
  });
};

// 根据id获取员工详情
export const getEmpDetail = (id: number) => {
  return request<any>({
    url: `${apiv1.getEmpDetail}/${id}`,
    method: 'GET'
  });
};
