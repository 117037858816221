import { Select } from '@arco-design/web-react';
const Option = Select.Option;

interface CompProps {
  value?: string
  onChange?: (val: string) => void
  groupList: any
  type?: string
}

const GroupSelector = (props: CompProps) => {
  const { groupList, type } = props;
  return (
    <Select
      placeholder="请选择擅长学科"
      allowClear
      showSearch
      filterOption={(inputVal, options) => {
        return options.props.children.toLowerCase().indexOf(inputVal.toLowerCase()) >= 0;
      }}
      mode={type === 'addOrUpdate' ? 'multiple' : undefined}
      {...props}
    >
      {
        !type && <Option key='全部' value="">全部</Option>
      }
      {groupList?.map((item: any) => (
        <Option key={item.name} value={item.id}>
          {item.name}
        </Option>
      ))}

    </Select>
  );
};

export default GroupSelector;
