import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { StyledLayout } from './style';
import Menu from './components/menu';
import { Layout } from '@arco-design/web-react';
import { useEffect, useState } from 'react';
import Header from './components/header';
import UserInfoContextProvider from '$components/user-info-context-provider';
import Router from './components/router';
import useMicroApp from '$hooks/use-micro-app';
import { paths } from '$const/paths';
import Login from '$pages/login';
import PermissionContextProvider from '$components/permission-context-provider/PermissionContextProvider';
import useProjectStoreLoader from '$hooks/use-project-store-loader';

const { Sider, Content } = Layout;

const PreLoad = () => {
  const { reload } = useProjectStoreLoader();
  useEffect(() => {
    // reload();
  }, [reload]);
  return <></>;
};

const _Layout = () => {
  const [collapse, setCollapse] = useState(false);
  const { mountPoint } = useMicroApp();

  return <BrowserRouter>
    <UserInfoContextProvider>
      <PermissionContextProvider>
        <Routes>
          <Route path={paths.login} element={<Login/>}/>
          <Route
            path="*"
            element={
              <StyledLayout>
                <PreLoad/>
                <Layout className="layout">
                  <Sider
                    collapsible
                    onCollapse={setCollapse}
                    width={170}
                    collapsedWidth={68}
                  >
                    <div className='logo'>{collapse ? '奇点' : '奇点后台系统'}</div>
                    <Menu />
                  </Sider>
                  <Layout className="layout-header-content">
                    <Header />
                    <Layout style={{ padding: '0 12px' }}>
                      <Content className="layout-content-wrap">
                        {mountPoint}
                        <Router/>
                      </Content>
                    </Layout>
                  </Layout>
                </Layout>
              </StyledLayout>
            }
          />
        </Routes>
      </PermissionContextProvider>
    </UserInfoContextProvider>
  </BrowserRouter>;
};

export default _Layout;
