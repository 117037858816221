import { registerMicroApps } from 'qiankun';
import { containerId, microAppList } from './const';

export const loadMicroApps = (loadingCb: (loading: boolean) => void) => {
  registerMicroApps(
    microAppList.map(microApp => {
      const { entry, name, activeRule } = microApp;
      return {
        name,
        entry,
        activeRule,
        container: `#${containerId}`
      };
    }),
    {
      beforeLoad: [
        async (app) => {
          loadingCb(true);
          console.log('[LifeCycle] before load %c%s', 'color: green;', app.name);
        }
      ],
      beforeMount: [
        async (app) => {
          console.log('[LifeCycle] before mount %c%s', 'color: green;', app.name);
        }
      ],
      afterMount: [
        async (app) => {
          loadingCb(false);
          console.log('[LifeCycle] after mount %c%s', 'color: green;', app.name);
        }
      ],
      afterUnmount: [
        async (app) => {
          loadingCb(false);
          console.log('[LifeCycle] after unmount %c%s', 'color: green;', app.name);
        }
      ]
    }
  );
};
