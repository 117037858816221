import { createContext } from 'react';

interface PermissionContextStruct {
  isAuth: (permission: string) => Boolean
  loadPermissionList: () => Promise<any>
}

export const PermissionContext = createContext<PermissionContextStruct>({
  isAuth: () => false,
  loadPermissionList: async () => []
});
