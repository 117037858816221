import { Select } from '@arco-design/web-react';

interface CompProps {
  value?: string
  onChange?: (val: string) => void
  roleList: any
  type?: string
}

const Option = Select.Option;
const RoleSelector = (props: CompProps) => {
  const { roleList, type } = props;
  return (
    <Select
      placeholder="请选择对应职能"
      allowClear
      showSearch
      filterOption={(inputVal, options) => {
        return options.props.children.toLowerCase().indexOf(inputVal.toLowerCase()) >= 0;
      }}
      mode={type === 'addOrUpdate' ? 'multiple' : undefined}
      {...props}
    >
      {
        !type && <Option key='全部' value="">全部</Option>
      }
      {roleList?.map((item: any) => (
        <Option key={item.roleName} value={item.roleId}>
          {item.roleName}
        </Option>
      ))}

    </Select>
  );
};

export default RoleSelector;
