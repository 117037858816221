export enum PROJECT_STATUS {
  /* —————— 文书+投递状态 —————— */
  /** 待择校 */
  SCHOOL_SELECTION = 10001,
  /** 准备素材 */
  SUBMIT_MATERIALS = 10002,
  /** 素材已提交 */
  ALREADY_SUBMIT_MATERIALS = 10003,
  /** 文书创作中 */
  DOCUMENT_CREATE = 10004,
  /** 文书品控中 */
  DOCUMENT_QUALITY = 10005,
  /** 文书品控中->开始品控 */
  START_DOCUMENT_QUALITY = 10006,
  /** 文书定稿中 */
  DOCUMENT_FINALIZATION = 10007,
  /** 语言校对中 */
  LANGUAGE_PROOFREADING = 10008,
  /** 终稿已交付 */
  DELIVERED = 10009,
  /** 投递中 */
  IN_DELIVERY = 10010,
  /** 网申投递 */
  ONLINE_DELIVERY = 10011,
  /** 投递完成 */
  DELIVERY_FINISHED = 10012,
  /** Offer */
  OFFER = 10013,
  /** Reject */
  REJECT = 10014,

  /* —————— 单文书状态 —————— */
  /** 待择校 */
  ONLY_DOCUMENT_SCHOOL_SELECTION = 20001,
  /** 准备素材 */
  ONLY_DOCUMENT_SUBMIT_MATERIALS = 20002,
  /** 素材已提交 */
  ONLY_DOCUMENT_ALREADY_SUBMIT_MATERIALS = 20003,
  /** 文书创作中 */
  ONLY_DOCUMENT_DOCUMENT_CREATE = 20004,
  /** 文书品控中 */
  ONLY_DOCUMENT_DOCUMENT_QUALITY = 20005,
  /** 文书品控中->开始品控 */
  ONLY_DOCUMENT_START_DOCUMENT_QUALITY = 20006,
  /** 文书定稿中 */
  ONLY_DOCUMENT_DOCUMENT_FINALIZATION = 20007,
  /** 语言校对中 */
  ONLY_DOCUMENT_LANGUAGE_PROOFREADING = 20008,
  /** 终稿已交付 */
  ONLY_DOCUMENT_DELIVERED = 20009,

  /* —————— 单投递状态 —————— */
  /** 待择校 */
  ONLY_DELIVERY_SCHOOL_SELECTION = 30001,
  /** 投递中 */
  ONLY_DELIVERY_IN_DELIVERY = 30010,
  /** 网申投递 */
  ONLY_DELIVERY_ONLINE_DELIVERY = 30011,
  /** 投递完成 */
  ONLY_DELIVERY_DELIVERY_FINISHED = 30012,
  /** Offer */
  ONLY_DELIVERY_OFFER = 30013,
  /** Reject */
  ONLY_DELIVERY_REJECT = 30014

}

export const projectStatusNameMap: { [key in PROJECT_STATUS]: string } = {
  [PROJECT_STATUS.SCHOOL_SELECTION]: '待择校',
  [PROJECT_STATUS.SUBMIT_MATERIALS]: '准备素材',
  [PROJECT_STATUS.ALREADY_SUBMIT_MATERIALS]: '素材已提交',
  [PROJECT_STATUS.DOCUMENT_CREATE]: '文书创作中',
  [PROJECT_STATUS.DOCUMENT_QUALITY]: '文书品控中(未开始)',
  [PROJECT_STATUS.START_DOCUMENT_QUALITY]: '文书品控中',
  [PROJECT_STATUS.DOCUMENT_FINALIZATION]: '文书定稿中',
  [PROJECT_STATUS.LANGUAGE_PROOFREADING]: '语言校对中',
  [PROJECT_STATUS.DELIVERED]: '终稿已交付',
  [PROJECT_STATUS.IN_DELIVERY]: '投递中',
  [PROJECT_STATUS.ONLINE_DELIVERY]: '网申投递',
  [PROJECT_STATUS.DELIVERY_FINISHED]: '投递完成',
  [PROJECT_STATUS.OFFER]: 'Offer',
  [PROJECT_STATUS.REJECT]: 'Reject',

  [PROJECT_STATUS.ONLY_DOCUMENT_SCHOOL_SELECTION]: '待择校',
  [PROJECT_STATUS.ONLY_DOCUMENT_SUBMIT_MATERIALS]: '准备素材',
  [PROJECT_STATUS.ONLY_DOCUMENT_ALREADY_SUBMIT_MATERIALS]: '素材已提交',
  [PROJECT_STATUS.ONLY_DOCUMENT_DOCUMENT_CREATE]: '文书创作中',
  [PROJECT_STATUS.ONLY_DOCUMENT_DOCUMENT_QUALITY]: '文书品控中',
  [PROJECT_STATUS.ONLY_DOCUMENT_START_DOCUMENT_QUALITY]: '文书品控中',
  [PROJECT_STATUS.ONLY_DOCUMENT_DOCUMENT_FINALIZATION]: '文书定稿中',
  [PROJECT_STATUS.ONLY_DOCUMENT_LANGUAGE_PROOFREADING]: '语言校对中',
  [PROJECT_STATUS.ONLY_DOCUMENT_DELIVERED]: '终稿已交付',

  [PROJECT_STATUS.ONLY_DELIVERY_SCHOOL_SELECTION]: '待择校',
  [PROJECT_STATUS.ONLY_DELIVERY_IN_DELIVERY]: '投递中',
  [PROJECT_STATUS.ONLY_DELIVERY_ONLINE_DELIVERY]: '网申投递',
  [PROJECT_STATUS.ONLY_DELIVERY_DELIVERY_FINISHED]: '投递完成',
  [PROJECT_STATUS.ONLY_DELIVERY_OFFER]: 'Offer',
  [PROJECT_STATUS.ONLY_DELIVERY_REJECT]: 'Reject'

};

export const updateToNextStatus = (status: PROJECT_STATUS) => {
  const statusUpdatemap = {
    [PROJECT_STATUS.SCHOOL_SELECTION]: PROJECT_STATUS.SUBMIT_MATERIALS,
    [PROJECT_STATUS.SUBMIT_MATERIALS]: PROJECT_STATUS.ALREADY_SUBMIT_MATERIALS,
    [PROJECT_STATUS.ALREADY_SUBMIT_MATERIALS]: PROJECT_STATUS.DOCUMENT_CREATE,
    [PROJECT_STATUS.DOCUMENT_CREATE]: PROJECT_STATUS.DOCUMENT_QUALITY,
    [PROJECT_STATUS.DOCUMENT_QUALITY]: PROJECT_STATUS.START_DOCUMENT_QUALITY,
    [PROJECT_STATUS.START_DOCUMENT_QUALITY]: PROJECT_STATUS.DOCUMENT_FINALIZATION,
    [PROJECT_STATUS.DOCUMENT_FINALIZATION]: PROJECT_STATUS.LANGUAGE_PROOFREADING,
    [PROJECT_STATUS.LANGUAGE_PROOFREADING]: PROJECT_STATUS.IN_DELIVERY,
    [PROJECT_STATUS.DELIVERED]: PROJECT_STATUS.IN_DELIVERY,
    [PROJECT_STATUS.IN_DELIVERY]: PROJECT_STATUS.ONLINE_DELIVERY,
    [PROJECT_STATUS.ONLINE_DELIVERY]: PROJECT_STATUS.DELIVERY_FINISHED,
    [PROJECT_STATUS.DELIVERY_FINISHED]: PROJECT_STATUS.OFFER,
    [PROJECT_STATUS.OFFER]: PROJECT_STATUS.OFFER,
    [PROJECT_STATUS.REJECT]: PROJECT_STATUS.REJECT,

    [PROJECT_STATUS.ONLY_DOCUMENT_SCHOOL_SELECTION]: PROJECT_STATUS.ONLY_DOCUMENT_SUBMIT_MATERIALS,
    [PROJECT_STATUS.ONLY_DOCUMENT_SUBMIT_MATERIALS]: PROJECT_STATUS.ONLY_DOCUMENT_ALREADY_SUBMIT_MATERIALS,
    [PROJECT_STATUS.ONLY_DOCUMENT_ALREADY_SUBMIT_MATERIALS]: PROJECT_STATUS.ONLY_DOCUMENT_DOCUMENT_CREATE,
    [PROJECT_STATUS.ONLY_DOCUMENT_DOCUMENT_CREATE]: PROJECT_STATUS.ONLY_DOCUMENT_DOCUMENT_QUALITY,
    [PROJECT_STATUS.ONLY_DOCUMENT_DOCUMENT_QUALITY]: PROJECT_STATUS.ONLY_DOCUMENT_START_DOCUMENT_QUALITY,
    [PROJECT_STATUS.ONLY_DOCUMENT_START_DOCUMENT_QUALITY]: PROJECT_STATUS.ONLY_DOCUMENT_DOCUMENT_FINALIZATION,
    [PROJECT_STATUS.ONLY_DOCUMENT_DOCUMENT_FINALIZATION]: PROJECT_STATUS.ONLY_DOCUMENT_LANGUAGE_PROOFREADING,
    [PROJECT_STATUS.ONLY_DOCUMENT_LANGUAGE_PROOFREADING]: PROJECT_STATUS.ONLY_DOCUMENT_DELIVERED,
    [PROJECT_STATUS.ONLY_DOCUMENT_DELIVERED]: PROJECT_STATUS.ONLY_DELIVERY_IN_DELIVERY,

    [PROJECT_STATUS.ONLY_DELIVERY_SCHOOL_SELECTION]: PROJECT_STATUS.ONLY_DELIVERY_IN_DELIVERY,
    [PROJECT_STATUS.ONLY_DELIVERY_IN_DELIVERY]: PROJECT_STATUS.ONLY_DELIVERY_ONLINE_DELIVERY,
    [PROJECT_STATUS.ONLY_DELIVERY_ONLINE_DELIVERY]: PROJECT_STATUS.ONLY_DELIVERY_DELIVERY_FINISHED,
    [PROJECT_STATUS.ONLY_DELIVERY_DELIVERY_FINISHED]: PROJECT_STATUS.ONLY_DELIVERY_OFFER,
    [PROJECT_STATUS.ONLY_DELIVERY_OFFER]: PROJECT_STATUS.ONLY_DELIVERY_OFFER,
    [PROJECT_STATUS.ONLY_DELIVERY_REJECT]: PROJECT_STATUS.ONLY_DELIVERY_REJECT
  };
  return statusUpdatemap[status];
};

export const projectStatusList = [
  { label: '待择校', value: [PROJECT_STATUS.SCHOOL_SELECTION, PROJECT_STATUS.ONLY_DOCUMENT_SCHOOL_SELECTION] },
  { label: '准备素材', value: [PROJECT_STATUS.SUBMIT_MATERIALS, PROJECT_STATUS.ONLY_DOCUMENT_SUBMIT_MATERIALS] },
  { label: '素材已提交', value: [PROJECT_STATUS.ALREADY_SUBMIT_MATERIALS, PROJECT_STATUS.ONLY_DOCUMENT_ALREADY_SUBMIT_MATERIALS] },
  { label: '文书创作中', value: [PROJECT_STATUS.DOCUMENT_CREATE, PROJECT_STATUS.ONLY_DOCUMENT_DOCUMENT_CREATE] },
  { label: '文书品控中', value: [PROJECT_STATUS.DOCUMENT_QUALITY, PROJECT_STATUS.START_DOCUMENT_QUALITY, PROJECT_STATUS.ONLY_DOCUMENT_DOCUMENT_QUALITY, PROJECT_STATUS.ONLY_DOCUMENT_START_DOCUMENT_QUALITY] },
  { label: '文书定稿中', value: [PROJECT_STATUS.DOCUMENT_FINALIZATION, PROJECT_STATUS.ONLY_DOCUMENT_DOCUMENT_FINALIZATION] },
  { label: '语言校对中', value: [PROJECT_STATUS.LANGUAGE_PROOFREADING, PROJECT_STATUS.ONLY_DOCUMENT_LANGUAGE_PROOFREADING] },
  { label: '终稿已交付', value: [PROJECT_STATUS.DELIVERED, PROJECT_STATUS.ONLY_DOCUMENT_DELIVERED] }
];

export const deliveryStatusList = [
  { label: '投递中', value: [PROJECT_STATUS.IN_DELIVERY, PROJECT_STATUS.ONLY_DELIVERY_IN_DELIVERY] },
  { label: '网申投递', value: [PROJECT_STATUS.ONLINE_DELIVERY, PROJECT_STATUS.ONLY_DELIVERY_ONLINE_DELIVERY] },
  { label: '投递完成', value: [PROJECT_STATUS.DELIVERY_FINISHED, PROJECT_STATUS.ONLY_DELIVERY_DELIVERY_FINISHED] },
  { label: 'Offer', value: [PROJECT_STATUS.OFFER, PROJECT_STATUS.ONLY_DELIVERY_OFFER] },
  { label: 'Reject', value: [PROJECT_STATUS.REJECT, PROJECT_STATUS.ONLY_DELIVERY_REJECT] }
];

export const projectAndDeliveryStatusList = [
  ...projectStatusList,
  ...deliveryStatusList
];

export const statusObject: { [key: string]: any } = {};

projectAndDeliveryStatusList.forEach(item => {
  statusObject[item.label] = item.value;
});

export const deliveryTabStatusList = [
  {
    label: '待择校',
    value: [
      PROJECT_STATUS.SCHOOL_SELECTION,
      PROJECT_STATUS.ONLY_DOCUMENT_SCHOOL_SELECTION
    ]
  },
  {
    label: '准备素材',
    value: [
      PROJECT_STATUS.SUBMIT_MATERIALS,
      PROJECT_STATUS.ONLY_DOCUMENT_SUBMIT_MATERIALS
    ]
  },
  {
    label: '待PS完成',
    value: [
      PROJECT_STATUS.ALREADY_SUBMIT_MATERIALS,
      PROJECT_STATUS.DOCUMENT_CREATE,
      PROJECT_STATUS.DOCUMENT_QUALITY,
      PROJECT_STATUS.START_DOCUMENT_QUALITY,
      PROJECT_STATUS.DOCUMENT_FINALIZATION,
      PROJECT_STATUS.LANGUAGE_PROOFREADING,

      PROJECT_STATUS.ONLY_DOCUMENT_ALREADY_SUBMIT_MATERIALS,
      PROJECT_STATUS.ONLY_DOCUMENT_DOCUMENT_CREATE,
      PROJECT_STATUS.ONLY_DOCUMENT_DOCUMENT_QUALITY,
      PROJECT_STATUS.ONLY_DOCUMENT_START_DOCUMENT_QUALITY,
      PROJECT_STATUS.ONLY_DOCUMENT_DOCUMENT_FINALIZATION,
      PROJECT_STATUS.ONLY_DOCUMENT_LANGUAGE_PROOFREADING
    ]
  },
  ...deliveryStatusList
];

export const getServiceName = (status: PROJECT_STATUS) => {
  if (status > 10000 && status < 20001) {
    return '文书+投递服务';
  } else if (status > 20000 && status < 30001) {
    return '文书服务';
  } else {
    return '投递服务';
  }
};

export enum PROJECT_SERVICE {
  /** 文书+投递服务 */
  DOCUMENT_DELIVERED_SERVICE = 1,
  /** 文书服务 */
  DOCUMENT_SERVICE = 2,
  /** 投递服务 */
  DELIVERED_SERVICE = 3,

}

export const projectServiceList = [
  {
    label: '全部',
    value: null
  },
  {
    label: '文书服务',
    value: [PROJECT_SERVICE.DOCUMENT_SERVICE, PROJECT_SERVICE.DOCUMENT_DELIVERED_SERVICE].join(',')
  },
  {
    label: '投递服务',
    value: [PROJECT_SERVICE.DELIVERED_SERVICE, PROJECT_SERVICE.DOCUMENT_DELIVERED_SERVICE].join(',')
  }
];

/** 创作人员枚举值 */
export enum ASSIGN_PERSON {
  /** 文案 */
  DOCUMENT = 1,
  /** 校友 */
  SCHOOL = 2,
  /** 品控 */
  QUALITY = 3,
  /** 翻译 */
  TRANSLATOR = 4
}

export const assignPersonMap = {
  [ASSIGN_PERSON.DOCUMENT]: '文案人员',
  [ASSIGN_PERSON.SCHOOL]: '校友',
  [ASSIGN_PERSON.QUALITY]: '品控人员',
  [ASSIGN_PERSON.TRANSLATOR]: '翻译人员'
};

export const assignPersonCascaderOptions = Object.keys(assignPersonMap).map(v => {
  const value: ASSIGN_PERSON = Number(v);
  const label = assignPersonMap[value];
  return {
    label,
    value,
    children: [
      {
        label: '已分配',
        value: 1
      },
      {
        label: '未分配',
        value: 0
      }
    ]
  };
});

/** 人员类型枚举 */
export enum PERSON_TYPE {
  /** 文案 */
  DOCUMENT = '文案',
  /** 校友 */
  SCHOOL = '校友',
}

export const personTypeMap = {
  [PERSON_TYPE.DOCUMENT]: '文案人员',
  [PERSON_TYPE.SCHOOL]: '校友'
};

export const personTypeOptions = [
  {
    label: '全部',
    value: null
  },
  {
    label: '文案人员',
    value: PERSON_TYPE.DOCUMENT
  },
  {
    label: '校友',
    value: PERSON_TYPE.SCHOOL
  }
];
