import { MenuItem, MenuItem as MenuItemType } from '$layout/type';

export const menuListToMenuMap = (menuList: MenuItem[], map?: Map<number, MenuItem>) => {
  return menuList.reduce((pre, cur) => {
    const { menuId, list } = cur;
    pre.set(menuId, cur);
    if (list.length) {
      menuListToMenuMap(list, pre);
    }
    return pre;
  }, map ?? new Map<number, MenuItem>());
};

export const getDefaultSelectKeys = (menuList: MenuItemType[]) => {
  let defaultSelectedKey: string | undefined;
  const { pathname } = location;
  const menulistTrasive = (list: MenuItemType[], parentId: number[]): number[] => {
    for (let i = 0; i < list.length; i++) {
      const menu = list[i];
      if (menu.list.length) {
        const result = menulistTrasive(menu.list, [...parentId, menu.menuId]);
        if (result.length) {
          return result;
        }
      } else {
        if (menu.url === pathname || `/${menu.url}` === pathname) {
          defaultSelectedKey = menu.menuId.toString();
          return [...parentId, menu.menuId];
        }
      }
    }
    return [];
  };
  const defaultOpenKeys = pathname === '/' ? [] : menulistTrasive(menuList, []);
  return {
    defaultOpenKeys,
    defaultSelectedKey
  };
};
