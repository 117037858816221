import { useEffect, useState } from 'react';

const useDarkMode = () => {
  const [isDarkMode, setIsDarkMode] = useState(
    window.matchMedia?.('(prefers-color-scheme: dark)').matches
  );

  useEffect(() => {
    if (isDarkMode) {
      document.body.setAttribute('arco-theme', 'dark');
    } else {
      document.body.removeAttribute('arco-theme');
    }
  }, [isDarkMode]);

  useEffect(() => {
    const darkThemeMq = window.matchMedia('(prefers-color-scheme: dark)');
    const changeHandler = (e: any) => {
      setIsDarkMode(e.matches);
    };
    darkThemeMq.addEventListener('change', changeHandler);
    return () => {
      darkThemeMq.removeEventListener('change', changeHandler);
    };
  });

  return {
    isDarkMode,
    setIsDarkMode
  };
};

export default useDarkMode;
