import { State } from '$store/projects-slice/interface';
import { writeData, readData } from './dbBase';

const DB_NAME = 'PERSONAL_STATEMENT';
const STORE_NAME = 'PROJECT_MAP';
const MY_VISIBLE_PROJECT_MAP_DATA_KEY = 'MY_VISIBLE_PROJECT_MAP_DATA_KEY';

export const readMyVisibleProjectMaps = async () => {
  return (await readData(DB_NAME, STORE_NAME, MY_VISIBLE_PROJECT_MAP_DATA_KEY)) as Partial<State>;
};

export const writeMyVisibleProjectMaps = async (data: Partial<State>) => {
  return await writeData(DB_NAME, STORE_NAME, MY_VISIBLE_PROJECT_MAP_DATA_KEY, data);
};
