import { UserInfo, VerifyNonceReq } from '$components/user-info-context-provider/type';
import { createContext } from 'react';

interface UserInfoContextStruct {
  userInfo: Partial<UserInfo>
  loaded: boolean
  loadUserInfo: () => Promise<any>
  logout: () => Promise<any>
  verifyNonce: (param: VerifyNonceReq) => {}

}

export const UserInfoContext = createContext<UserInfoContextStruct>({
  userInfo: {},
  loaded: true,
  loadUserInfo: async () => {},
  logout: async () => {},
  verifyNonce: async () => {}
});
