export enum MicroAppType {
  IFRAME = 'IFRAME',
  QIANKUN = 'QIANKUN'
}

export const defaultPagination: PaginationStruct = {
  pageSize: 10,
  current: 1,
  total: 0
};

export const QINIU_UPLOAD_ACTION = 'https://upload.qiniup.com/';

export const emergencyContactOptions = [
  { label: '父母', value: '父母' },
  { label: '兄弟姐妹', value: '兄弟姐妹' },
  { label: '子女', value: '子女' },
  { label: '朋友', value: '朋友' },
  { label: '同事', value: '同事' },
  { label: '雇主', value: '雇主' },
  { label: '其他', value: '其他' }

];
