/* eslint-disable prefer-promise-reject-errors */
import axios from 'axios';
import { errorCode } from './const';
import { paths } from '$const/paths';
import { Message } from '@arco-design/web-react';
import Cookies from 'js-cookie';

const instance = axios.create({
  baseURL: process.env.APP_API_BASE_URL,
  timeout: 5 * 60 * 1000,
  method: 'POST',
  withCredentials: true
});

instance.interceptors.request.use(
  function (config) {
    const headers = {
      ...config.headers,
      token: Cookies.get('token') ? `${Cookies.get('token')}` : undefined,
      from: 'personal-statement-admin-end'
    };
    return {
      ...config,
      headers
    };
  },
  async function (error) {
    return await Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    switch (response.data?.code) {
      case errorCode.NotAuthorized:
        window.location.href = `${window.location.origin}${paths.login}`;
        Cookies.remove('token', {
          domain: document.domain.split('.').slice(-2).join('.')
        });
        return null;
      case errorCode.GeneralError:
        Message.error(response.data?.msg);
        throw new Error(response.data?.msg);
      default:
        break;
    }
    return response.data;
  },
  async function (error) {
    Message.error(error.message);
    console.error('Request Error', error);
    const response = error.response;
    if (axios.isCancel(error)) {
      return await Promise.reject({
        errorCode: errorCode.CancelRequest,
        errorMsg: 'cancel request'
      });
    }

    return await Promise.reject({
      errorCode: Boolean((response?.status)) || errorCode.NetworkError,
      errorMsg: error.toString()
    });
  }
);
export default instance;
