import { PROJECT_STATUS } from '$const/projects';
import { GROUP_TYPE } from '$hooks/use-stastistic/const';

export const AssignDocumentEmployeeOperationName = '文案/校友具体人员分配';

export enum STASTISTIC {
  // ======学员数据维度======
  /** 全套服务学生数量 lite+plus+prime（购买对应产品分类用户的总数） */
  BAAAAA = 'BAAAAA',
  /** 各产品分类下的用户总数 - 单项 */
  BAAAABA = 'BAAAABA',
  /** 各产品分类下的用户总数 - lite */
  BAAAABB = 'BAAAABB',
  /** 各产品分类下的用户总数 - plus */
  BAAAABC = 'BAAAABC',
  /** 各产品分类下的用户总数 - prime */
  BAAAABD = 'BAAAABD',
  /** 单项服务学生数量：购买对应单项服务产品分类的总数（新增单项服务产品分类） */
  BAAAAC = 'BAAAAC',
  /** 学员总数（总用户数）--已付费用户 */
  BAAAAD = 'BAAAAD',
  /**
   * 总志愿数：含文书服务或投递服务的总和
   * 如果仅有投递，也算1；仅有文书服务，也算1；如果同一个文书和同一个投递，则算1 —— 系统默认 一个订单里同时包含文书和投递时，文书和投递一对一算1个志愿
   */
  BAAAAE = 'BAAAAE',
  /** 待择校数量：项目状态为「待择校」的总和 */
  BAAAAF = 'BAAAAF',
  /** 已择校数量：项目已定志愿（对应项目名称不为空）的总和 */
  BAAAAG = 'BAAAAG',
  /** 总文书志愿数：含文书服务的总和 */
  BAAAAH = 'BAAAAH',
  /** 总投递志愿数：含投递服务的总和 */
  BAAAAI = 'BAAAAI',

  // ======文书数据维度======
  /** 总文书志愿数：含文书服务的总和 */
  BAAAAJ = 'BAAAAJ',

  // ======素材数据维度======
  /** 未提交素材总数*：计算「准备素材」的志愿总和 */
  BAAAAK = 'BAAAAK',
  /** 已提交素材总数*：计算「素材已提交」及以后的志愿总和 */
  BAAAAL = 'BAAAAL',
  /** 需要文书服务的学员总数 = 含有「文书服务」的学员总数 */
  BAAAAM = 'BAAAAM',
  /** 一个素材都未上传的学生总数 = 在【A】中，学员志愿状态均为「准备素材」的学生总和 */
  BAAAAN = 'BAAAAN',

  // ======匹配（分配）维度======
  /** 文案接单量 = 【【C】已提交素材总数*】下选择分配「文案」具体文案的志愿总数 */
  BAAAAO = 'BAAAAO',
  /** 校友接单量 =【【C】已提交素材总数*】下选择分配「校友」具体校友的志愿总数 */
  BAAAAP = 'BAAAAP',
  /** 待分配 = 【【C】已提交素材总数*】下未分配文案/校友的志愿总数 */
  BAAAAQ = 'BAAAAQ',
  /** 待分配-校友 = 【C】下未分配具体校友的志愿总数 */
  BAAAAR = 'BAAAAR',
  /**
   * TODO
   * 校友匹配逾期总数= 【B】下且24小时未匹配具体校友的志愿总数
   *   - 判断标准：
    - 分配「校友」动作完成如果发生在12:00pm前，且分配到具体校友的时间超过T+1的12:00pm，记一次逾期
    - 分配「校友」动作完成如果发生在12:00pm后，且分配到具体校友的时间超过T+2的00:00am，记一次逾期
   * */
  BAAAAS = 'BAAAAS',
  /** TODO 匹配逾期率 =【E】/【B】 */
  BAAAAT = 'BAAAAT',

  // ======创作/品控数据维度======
  /** 【A】待创作：【【C】已提交素材总数*】下所有项目进度为「已提交素材」的总数； */
  BAAAAU = 'BAAAAU',
  /** 创作中：【【C】已提交素材总数*】下所有项目进度为「内容创作中」的总数； */
  BAAAAV = 'BAAAAV',
  /** 文案创作中：项目进度为「内容创作中」且分配具体文案人员/组长 */
  BAAAAW = 'BAAAAW',
  /** 校友创作中：项目进度为「内容创 */
  BAAAAX = 'BAAAAX',
  /** 品控中：【【C】已提交素材总数*】下所有项目进度为「内容品控中」的总数 */
  BAAAAY = 'BAAAAY',
  /** 品控未开始：【【C】已提交素材总数*】下所有项目进度为「内容品控中」，且未点击「开始品控」的总数； */
  BAAAAZ = 'BAAAAZ',
  /** 品控中-校友：【【C】已提交素材总数*】下所有项目进度为「内容品控中」且文案/校友分配了具体校友、且已点击「开始品控」的总数 */
  BAAABA = 'BAAABA',
  // /** 项目逾期：【*D】下所有项目进度以「已提交素材」——「内容定稿中」持续时间超过15 自然日的总数 */
  // BAAABB = 'BAAABB',
  /** 当前项目逾期 */
  BAAABBA = 'BAAABBA',
  /** 历史项目逾期 */
  BAAABBB = 'BAAABBB',
  /** 当前文案/校友逾期：【*D】下，状态为「已提交素材」，且从匹配到具体校友/文案人员开始，直到状态变为「内容品控中」，超过七个自然日（T+7）的总数 */
  BAAABCA = 'BAAABCA',
  /** 创作逾期（校友） */
  BAAABCAA = 'BAAABCAA',
  /** 历史文案/校友逾期：【*D】下，状态为「已提交素材」，且从匹配到具体校友/文案人员开始，直到状态变为「内容品控中」，超过七个自然日（T+7）的总数 */
  BAAABCB = 'BAAABCB',
  /** 当前品控逾期：【*D】下，校友点击【提交文书】（给品控）开始，直到状态变为「内容定稿中」，持续超过七个自然日（T+7）的总数 */
  BAAABDA = 'BAAABDA',
  /** 历史品控逾期：【*D】下，校友点击【提交文书】（给品控）开始，直到状态变为「内容定稿中」，持续超过七个自然日（T+7）的总数 */
  BAAABDB = 'BAAABDB',
  /** 初稿交付 = 所有「提交文书」（给学生）完成动作的总数；「提交文书记录初稿交付一次，记录提交时间」 */
  BAAABE = 'BAAABE',
  /** 初稿交付-本周 */
  BAAABF = 'BAAABF',
  /** 初稿交付-本月 */
  BAAABG = 'BAAABG',
  /** 定稿中：=【*D】下所有项目进度为「内容定稿中」状态下的总数 */
  BAAABH = 'BAAABH',
  /** 已定稿：= 所有「确认定稿」完成动作的总数；「确认定稿记录初稿交付一次，记录提交时间」 */
  BAAABI = 'BAAABI',
  /** 已定稿-本周 */
  BAAABJ = 'BAAABJ',
  /** 已定稿-本月 */
  BAAABK = 'BAAABK',

  // ======翻译数据维度======
  /** 终稿交付 */
  BAAABL = 'BAAABL',
  /** 终稿交付中：【*D】下所有项目进度为「语言校对中」状态下的总数 */
  BAAABM = 'BAAABM',
  /** 终稿已交付： 所有「提交终稿」完成动作的总数；「提交终稿记录初稿交付一次，记录提交时间」 */
  BAAABN = 'BAAABN',
  /** 当前终稿交付逾期：「语言校对中」持续超过七个自然日（T+7）的总数 */
  BAAABOA = 'BAAABOA',
  /** 历史终稿交付逾期：「语言校对中」持续超过七个自然日（T+7）的总数 */
  BAAABOB = 'BAAABOB',

  // ======投递数据维度======
  /** 需投递总数 = 「lite+plus+prime」的 志愿总和+「单项服务-投递服务」的总和 = 【*E】总投递志愿数：含投递服务的总和 */
  BAAABP = 'BAAABP',
  /** 已投递总数量 = 项目状态为「网申投递」的总和 */
  BAAABQ = 'BAAABQ',
  /** 待投递总数量 = 【A】-【B】 */
  BAAABR = 'BAAABR',
  /** 投递总完成率=【B】/【A】 */
  BAAABS = 'BAAABS',
  /** 全部投递完的学员数量 = 以用户为单位，所有投递服务为「网申投递」及以后的用户总和 */
  BAAABT = 'BAAABT',
  /** 未全部投递完的数量 = 以用户为单位，只要投递服务有「网申投递」之前状态的用户总和 */
  BAAABU = 'BAAABU',
  /** 需要投递服务的学员总数 = 含有「投递服务」的学员总数 */
  BAAABV = 'BAAABV',
  /** 未全部投递率【H】= 【F】/【G】 */
  BAAABW = 'BAAABW',
}

export const stastisticNameMap: { [key in string]: string } = {
  [STASTISTIC.BAAAAA]: '全套服务学生数量',
  [STASTISTIC.BAAAABA]: '各产品分类下的用户总数 - 单项',
  [STASTISTIC.BAAAABB]: '各产品分类下的用户总数 - lite',
  [STASTISTIC.BAAAABC]: '各产品分类下的用户总数 - plus',
  [STASTISTIC.BAAAABD]: '各产品分类下的用户总数 - prime',
  [STASTISTIC.BAAAAC]: '单项服务学生数量',
  [STASTISTIC.BAAAAD]: '已付费用户数',
  [STASTISTIC.BAAAAE]: '总志愿数：含文书服务或投递服务的总和',
  [STASTISTIC.BAAAAF]: '待择校数量',
  [STASTISTIC.BAAAAG]: '已择校数量',
  [STASTISTIC.BAAAAH]: '总文书志愿数',
  [STASTISTIC.BAAAAI]: '总投递志愿数',
  [STASTISTIC.BAAAAJ]: '总文书志愿数',
  [STASTISTIC.BAAAAK]: '「准备素材」的志愿总和',
  [STASTISTIC.BAAAAL]: '「素材已提交」及以后的志愿总和',
  [STASTISTIC.BAAAAM]: '需要文书服务的学员总数',
  [STASTISTIC.BAAAAN]: '需要文书服务的学员总数',
  [STASTISTIC.BAAAAO]: '文案接单量',
  [STASTISTIC.BAAAAP]: '校友接单量',
  [STASTISTIC.BAAAAQ]: '待分配',
  [STASTISTIC.BAAAAR]: '待分配-校友',
  [STASTISTIC.BAAAAS]: '校友匹配逾期总数',
  [STASTISTIC.BAAAAT]: '匹配逾期率',
  [STASTISTIC.BAAAAU]: '待创作',
  [STASTISTIC.BAAAAV]: '创作中',
  [STASTISTIC.BAAAAW]: '文案创作中',
  [STASTISTIC.BAAAAX]: '校友创作中',
  [STASTISTIC.BAAAAY]: '品控中',
  [STASTISTIC.BAAAAZ]: '品控未开始',
  [STASTISTIC.BAAABA]: '品控中-校友',
  [STASTISTIC.BAAABBA]: '项目逾期-当前逾期',
  [STASTISTIC.BAAABBB]: '项目逾期-历史逾期',
  [STASTISTIC.BAAABCA]: '文案/校友逾期-当前逾期',
  [STASTISTIC.BAAABCB]: '文案/校友逾期-历史逾期',
  [STASTISTIC.BAAABDA]: '品控逾期-当前逾期',
  [STASTISTIC.BAAABDB]: '品控逾期-历史逾期',
  [STASTISTIC.BAAABE]: '初稿交付',
  [STASTISTIC.BAAABF]: '初稿交付-本周',
  [STASTISTIC.BAAABG]: '初稿交付-本月',
  [STASTISTIC.BAAABH]: '定稿中',
  [STASTISTIC.BAAABI]: '已定稿',
  [STASTISTIC.BAAABJ]: '已定稿-本周',
  [STASTISTIC.BAAABK]: '已定稿-本月',
  [STASTISTIC.BAAABL]: '终稿交付',
  [STASTISTIC.BAAABM]: '终稿交付中',
  [STASTISTIC.BAAABN]: '终稿已交付',
  [STASTISTIC.BAAABOA]: '终稿交付-当前逾期',
  [STASTISTIC.BAAABOB]: '终稿交付-历史逾期',
  [STASTISTIC.BAAABP]: '需投递总数',
  [STASTISTIC.BAAABQ]: '已投递总数量',
  [STASTISTIC.BAAABR]: '待投递总数量',
  [STASTISTIC.BAAABS]: '投递总完成率',
  [STASTISTIC.BAAABT]: '全部投递完的学员数量',
  [STASTISTIC.BAAABU]: '未全部投递完的数量',
  [STASTISTIC.BAAABV]: '需要投递服务的学员总数 ',
  [STASTISTIC.BAAABW]: '未全部投递率'
};

export const projectOperationStatusNameMap: { [key in PROJECT_STATUS]: string } = {
  [PROJECT_STATUS.SCHOOL_SELECTION]: '选择专业',
  [PROJECT_STATUS.SUBMIT_MATERIALS]: '',
  [PROJECT_STATUS.ALREADY_SUBMIT_MATERIALS]: '',
  [PROJECT_STATUS.DOCUMENT_CREATE]: '提交素材',
  [PROJECT_STATUS.DOCUMENT_QUALITY]: '进入品控',
  [PROJECT_STATUS.START_DOCUMENT_QUALITY]: '提交定稿',
  [PROJECT_STATUS.DOCUMENT_FINALIZATION]: '',
  [PROJECT_STATUS.LANGUAGE_PROOFREADING]: '用户定稿',
  [PROJECT_STATUS.DELIVERED]: '终稿交付',
  [PROJECT_STATUS.IN_DELIVERY]: '投递中',
  [PROJECT_STATUS.ONLINE_DELIVERY]: '网申投递',
  [PROJECT_STATUS.DELIVERY_FINISHED]: '投递完成',
  [PROJECT_STATUS.OFFER]: 'Offer',
  [PROJECT_STATUS.REJECT]: 'Reject',

  [PROJECT_STATUS.ONLY_DOCUMENT_SCHOOL_SELECTION]: '选择专业',
  [PROJECT_STATUS.ONLY_DOCUMENT_SUBMIT_MATERIALS]: '',
  [PROJECT_STATUS.ONLY_DOCUMENT_ALREADY_SUBMIT_MATERIALS]: '',
  [PROJECT_STATUS.ONLY_DOCUMENT_DOCUMENT_CREATE]: '提交素材',
  [PROJECT_STATUS.ONLY_DOCUMENT_DOCUMENT_QUALITY]: '进入品控',
  [PROJECT_STATUS.ONLY_DOCUMENT_START_DOCUMENT_QUALITY]: '提交定稿',
  [PROJECT_STATUS.ONLY_DOCUMENT_DOCUMENT_FINALIZATION]: '',
  [PROJECT_STATUS.ONLY_DOCUMENT_LANGUAGE_PROOFREADING]: '用户定稿',
  [PROJECT_STATUS.ONLY_DOCUMENT_DELIVERED]: '终稿交付',

  [PROJECT_STATUS.ONLY_DELIVERY_SCHOOL_SELECTION]: '待择校',
  [PROJECT_STATUS.ONLY_DELIVERY_IN_DELIVERY]: '投递中',
  [PROJECT_STATUS.ONLY_DELIVERY_ONLINE_DELIVERY]: '网申投递',
  [PROJECT_STATUS.ONLY_DELIVERY_DELIVERY_FINISHED]: '投递完成',
  [PROJECT_STATUS.ONLY_DELIVERY_OFFER]: 'Offer',
  [PROJECT_STATUS.ONLY_DELIVERY_REJECT]: 'Reject'
};

export const groupTypeOperationMap: { [key: number]: string } = {
  [GROUP_TYPE.DOCUMENT]: '文案/校友分配',
  [GROUP_TYPE.QA]: '品控人员分配',
  [GROUP_TYPE.TRANSLATOR]: '翻译人员分配'
};
