import request from '$services/request';
import { apiv2 } from '$services/url';

export const changePassword = (data: { password: string }) => {
  return request({
    url: apiv2.changePassword,
    method: 'POST',
    data
  });
};
