import request from '$services/request';
import { apiv1 } from '$services/url';
import { GetMenuResp } from './type';

export const getMenu = () => {
  return request<GetMenuResp>({
    url: apiv1.getMenu,
    method: 'GET'
  });
};
