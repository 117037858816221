import { configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import thunkMiddleWare from 'redux-thunk';
import { createLogger } from 'redux-logger';
import projectSlice from './projects-slice';
import { AppSelector, AppThunkDispatch } from './interface';

const logger = createLogger({

}) as any;

const store = configureStore({
  reducer: {
    projects: projectSlice.reducer
  },
  devTools: true,
  middleware: [
    thunkMiddleWare,
    logger
  ]
});

export const useStoreSelector: AppSelector = useSelector;
export const useStoreDispatch = () => useDispatch<AppThunkDispatch>();

export * from './projects-slice';

export * from './interface';

export default store;
