import styled from 'styled-components';

export const StyledLogin = styled.div`
width: 100%;
height: 100%;
  .page-container{
    background-color: rgba(38, 50, 56, 0.6);
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    .left{
      padding-left: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 400;
      font-size: 48px;
      color: #fff;
      font-family: Helvetica;
    }
    .right{
      width: 30%;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      .tabs{
        padding: 0px 40px;
        width: 100%;
        .arco-form{
          padding: 20px 0px;
        }
        .arco-btn{
          width: 100%;
        }
        .qr-code{
          width: 50%;
          margin: 10px auto 0px auto;
        }
      }
    }
    ::before{
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      background-image: url(https://qnfile.personalstatement.cn/admin-static-test/202301142048/static/img/bg02.f71cf25.png);
      background-size: cover;
    }
  }
`;
