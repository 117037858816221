import { Suspense, useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import useRouters from '$hooks/useRouters';
import { pathsNameMap } from '$const/paths';
import { IconLoading } from '@arco-design/web-react/icon';

const _Router = () => {
  const routers = useRouters();

  const location = useLocation();

  useEffect(() => {
    document.title = pathsNameMap[location.pathname] || '奇点教育';
  }, [location]);

  return <Suspense fallback={<IconLoading/>}>
    <Routes>
      {routers.map((item) => (
        <Route key={item.path} {...item} />
      ))}
    </Routes>
  </Suspense>;
};

export default _Router;
