import { FEISHU_APP_ID, OAUTH_REDIRECT_URL } from '$const/feishu';
import request from '$services/request';
import { apiv1 } from '$services/url';

export const feishuOAuth = () => {
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  const oAuthUrl = `https://open.feishu.cn/open-apis/authen/v1/index?redirect_uri=${encodeURIComponent(OAUTH_REDIRECT_URL)}&app_id=${FEISHU_APP_ID}&state=test`;
  // @ts-expect-error
  window.location = oAuthUrl;
};

export const getLoginQRCode = (params: { signature: number }) => {
  return request<string>({
    url: apiv1.getQRCode,
    params,
    method: 'GET'
  });
};

export interface CheckIfLoginResp {
  result: boolean
  status: number
  data?: string
  phone?: string
  token?: string
  unionId?: string
}

export const checkIfLogin = (params: { signature: number }) => {
  return request<CheckIfLoginResp>({
    url: apiv1.checkLogin,
    method: 'GET',
    params
  });
};

export const usernamePasswordLogin = (data: { username: string, password: string }) => {
  return request<{ token: string }>({
    url: apiv1.usernamePasswordLogin,
    method: 'POST',
    data
  });
};

// 获取手机验证码
export const apiGetCode = (params: { mail: string }) => {
  return request({
    url: apiv1.getSmsCode,
    method: 'GET',
    params
  });
};

// 手机号验证码登录
export const apiPhoneCodeLogin = (data: { phone: string, code: string, unionId: string }) => {
  return request({
    url: apiv1.getSysVerify,
    method: 'POST',
    data
  });
};
