import { UserInfoContext } from '$context/UserInfoContext';
import { useRequest } from 'ahooks';
import { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { UserInfo } from './type';
import { getUserInfo, verifyNonce as _verifyNonce, getMyRole } from './service';

const UserInfoContextProvider = (props: PropsWithChildren<{}>) => {
  const { children } = props;
  const [userInfo, setUserInfo] = useState<Partial<UserInfo>>({});
  const [loaded, setLoaded] = useState(false);
  const [triedLoadUserInfo, setTriedLoadUserInfo] = useState(false);

  const { runAsync: runGetMyRole } = useRequest(getMyRole, { manual: true });

  const { runAsync: loadUserInfo } = useRequest(getUserInfo, {
    manual: true,
    onSuccess: async (res) => {
      const data_role = await runGetMyRole();
      setTriedLoadUserInfo(true);
      setUserInfo({
        ...res.user,
        data_role
      } || {});
      setLoaded(true);
    }
  });

  const { runAsync: verifyNonce } = useRequest(_verifyNonce, {
    manual: true
  });

  const _logout = useCallback(async () => {
    setUserInfo({});
  }, []);

  useEffect(() => {
    if (!triedLoadUserInfo) {
      // void loadUserInfo();
    }
  }, [loadUserInfo, triedLoadUserInfo, userInfo]);

  return <UserInfoContext.Provider value={{
    userInfo,
    loaded,
    loadUserInfo,
    logout: _logout,
    verifyNonce
  }}>
    {children}
  </UserInfoContext.Provider>;
};

export default UserInfoContextProvider;
