const apiBase = '';

export const apiv2 = {
  homeData: `${apiBase}/home/homedata.json`,
  getMyRole: `${apiBase}/apiv2/employee/getMyRole`,
  verifyNonce: `${apiBase}/apiv2/lark/verify_nonce`,
  createInsight: `${apiBase}/apiv2/insights/create`,
  updateInsight: `${apiBase}/apiv2/insights/update`,
  getInsightList: `${apiBase}/apiv2/insights/list`,
  getProject: `${apiBase}/apiv2/project/get`,
  createRefusal: `${apiBase}/apiv2/refusal/create`,
  updateRefusal: `${apiBase}/apiv2/refusal/update`,
  listRefusal: `${apiBase}/apiv2/refusal/list`,
  createCourse: `${apiBase}/apiv2/course/create`,
  updateCourse: `${apiBase}/apiv2/course/update`,
  listCourse: `${apiBase}/apiv2/course/list`,
  getUploadToken: `${apiBase}/apiv2/qiniu/uploadToken`,
  getCountrySchoolAcademy: `${apiBase}/apiv2/major/country-school-academy`,
  listSubject: `${apiBase}/apiv2/major/subjects`,
  listMajor: `${apiBase}/apiv2/major/list`,
  listEmployee: `${apiBase}/apiv2/employee/list`,
  createMajor: `${apiBase}/apiv2/major/create`,
  updateMajor: `${apiBase}/apiv2/major/update`,
  createSubject: `${apiBase}/apiv2/subject/create`,
  updateSubject: `${apiBase}/apiv2/subject/update`,
  listOrder: `${apiBase}/apiv2/order/list`,
  getProductClassify: `${apiBase}/apiv2/product/classify/list`,
  getproductLabels: `${apiBase}/apiv2/product/label/list`,
  listCollege: `${apiBase}/apiv2/college/list`,
  createCollege: `${apiBase}/apiv2/college/create`,
  updateCollege: `${apiBase}/apiv2/college/update`,
  listCountry: `${apiBase}/apiv2/country/list`,
  createCountry: `${apiBase}/apiv2/country/create`,
  updateCountry: `${apiBase}/apiv2/country/update`,
  updateDepartment: `${apiBase}/apiv2/department/update`,
  createDepartment: `${apiBase}/apiv2/department/create`,
  listOrderByUser: `${apiBase}/apiv2/order/list_by_user`,
  getProjectDetail: `${apiBase}/apiv2/project/detail`,
  changePassword: `${apiBase}/apiv2/user/changePassword`,
  assignEmployeeToUser: `${apiBase}/apiv2/employee/assign`,
  markUser: `${apiBase}/apiv2/user/mark`,
  selectMajorForProject: `${apiBase}/apiv2/project/selectMajor`,
  getCountrySchoolMajor: `${apiBase}/apiv2/major/country-school-major`,
  getMajorDetail: `${apiBase}/apiv2/major/detail`,
  employeeGroupTree: `${apiBase}/apiv2/employee/groupTree`,
  assignEmployeeToProject: `${apiBase}/apiv2/employee/assignToProject`,
  refreshContractStatus: `${apiBase}/apiv2/order/refreshContractStatus`,
  updateProjectStatus: `${apiBase}/apiv2/project/updateStatus`,
  uploadProjectFiles: `${apiBase}/apiv2/project/uploadFile`,
  markOrder: `${apiBase}/apiv2/order/remark`,
  previewContract: `${apiBase}/apiv2/order/previewContract`,
  assignEmployeeToOrder: `${apiBase}/apiv2/employee/assignToOrder`,
  listAllMajorStructed: `${apiBase}/apiv2/major/listAllStructed`,
  downloadContract: `${apiBase}/apiv2/order/downloadContract`,
  permissionList: `${apiBase}/apiv2/permission/list`,
  voluntaryManageList: `${apiBase}/apiv2/wish/list`,
  listProjects: `${apiBase}/apiv2/dashboard/list`,
  mapEmployeeDetail: `${apiBase}/apiv2/dashboard/mapEmployeeDetail`,
  mapMajorDetail: `${apiBase}/apiv2/dashboard/mapMajorDetail`,
  mapUserDetail: `${apiBase}/apiv2/dashboard/mapUserDetail`,
  mapProductDetail: `${apiBase}/apiv2/dashboard/mapProductDetail`,
  mapOrderDetail: `${apiBase}/apiv2/dashboard/mapOrderDetail`,
  mapAttachmentsDetail: `${apiBase}/apiv2/dashboard/mapAttachmentsDetail`,
  listSimple: `${apiBase}/apiv2/dashboard/listSimple`,
  listMyVisibleProjects: `${apiBase}/apiv2/dashboard/listMyVisibleProjects`,
  getMyVisibleProjectMaps: `${apiBase}/apiv2/dashboard/getMyVisibleProjectMaps`,
  clearCache: `${apiBase}/apiv2/temp/clearCache`,
  getStudentList: `${apiBase}/apiv2/user/list`,
  getStudentDeatil: `${apiBase}/apiv2/user/detail`,
  updateStudent: `${apiBase}/apiv2/user/update`,
  getChooseSchoolPlanList: `${apiBase}/apiv2/plan/list`,
  createChooseSchoolPlan: `${apiBase}/apiv2/plan/create`,
  updateChooseSchoolPlan: `${apiBase}/apiv2/plan/update`,
  getSchoolPlanDetail: `${apiBase}/apiv2/plan/detail`,
  updateSchoolSharingStatus: `${apiBase}/apiv2/plan/updateSharing`,
  createWritingAdvise: `${apiBase}/apiv2/major/writing-advise/create`,
  updateWritingAdvise: `${apiBase}/apiv2/major/writing-advise/update`,
  listWritingAdvise: `${apiBase}/apiv2/major/writing-advise/list`
};

export const apiv1 = {
  getUserInfo: `${apiBase}/sys/user/info`,
  getMenu: `${apiBase}/sys/menu/nav`,
  getCountryList: `${apiBase}/content/jdcountry/list`,
  getCollegeList: `${apiBase}/content/jdcollege/list`,
  getProjectList: `${apiBase}/content/jdproject/projectList`,
  getQRCode: `${apiBase}/api/wx/wmws/createQrCode`,
  checkLogin: `${apiBase}/sys/whetherEmployeeTheLogin`,
  usernamePasswordLogin: `${apiBase}/sys/login`,
  getPreviewContractUrl: `${apiBase}/product/jdproduct/previewTemplate`,
  getProjectDetail: `${apiBase}/content/jdproject/info`,
  updateTemplate: `${apiBase}/product/jdproduct/updateTemplate`,
  createEmployee: `${apiBase}/employee/jdemployeecreation/save`,
  checkPhone: `${apiBase}/employee/jdemployeecreation/checkMobile`,
  getSmsCode: `${apiBase}/employee/jdemployeecreation/mailvcode`,
  getSysVerify: `${apiBase}/sys/verify`,
  getEmployeeList: `${apiBase}/employee/jdemployeecreation/list`,
  getSysRoleList: `${apiBase}/sys/role/select`,
  getJdgroupList: `${apiBase}/content/jdgroup/list`,
  addEmp: `${apiBase}/employee/jdemployeecreation/save`,
  updateEmp: `${apiBase}/employee/jdemployeecreation/update`,
  deleteEmp: `${apiBase}/employee/jdemployeecreation/delete`,
  checkMobile: `${apiBase}/employee/jdemployeecreation/checkMobile`,
  getEmpDetail: `${apiBase}/employee/jdemployeecreation/info`
};
