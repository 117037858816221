import request from '$services/request';
import { apiv1, apiv2 } from '$services/url';
import { GetUserInfoResp, VerifyNonceReq } from './type';

export const getUserInfo = () => {
  return request<GetUserInfoResp>({
    url: apiv1.getUserInfo,
    method: 'GET'
  });
};

export const verifyNonce = (data: VerifyNonceReq) => {
  const userInfo = request({
    url: apiv2.verifyNonce,
    method: 'POST',
    data
  });
  return userInfo;
};

export const getMyRole = () => {
  return request<number>({
    url: apiv2.getMyRole,
    method: 'POST',
    data: {}
  });
};
