import { PermissionContext } from '$context/PermissionContext';
import { fetchPermissionList } from './util';
import { useRequest } from 'ahooks';
import { PropsWithChildren, useCallback, useState } from 'react';

/**
 * usage: const { isAuth } = useContext(PermissionContext);
 * const isXXXAuth = isAuth("XXX");
 */
const PermissionContextProvider = (props: PropsWithChildren<{}>) => {
  const { children } = props;
  const [permissionList, setPermissionList] = useState<string[]>([]);

  const { runAsync: loadPermissionList } = useRequest(fetchPermissionList, {
    manual: true,
    onSuccess: (res) => {
      setPermissionList(res);
    }
  });
  const isAuth = useCallback((permission: string) => {
    return !!permissionList?.some(p => p === permission);
  }, [permissionList]);

  return <PermissionContext.Provider value={{ isAuth, loadPermissionList }}>
    {children}
  </PermissionContext.Provider>;
};

export default PermissionContextProvider;
