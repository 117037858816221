import styled from 'styled-components';

export const StyledJdemployeeCreation = styled.div`
  background: #fff;
  box-sizing: border-box;
  .search-form {
    box-sizing: border-box;
    .arco-picker {
      width: 100%;
    }
    .btns-area {
      .reset-btn {
        margin-left: 10px;
      }
    }
  }
  .btn-opear-container{
    .delete-btn{
      margin-left: 20px;
    }
  }
  .upload-btns-area {
    margin-top: 15px;
    .reupload-btn {
      margin-left: 8px;
    }
  }
  .table-wrapper {
    margin-top: 20px;
    width: 100%;
  }

`;

export const StyledAddEditModal = styled.div`
  .add-tips-link-container{
    .add-emp-tips{
      color: red;
    }
    .link{
      margin-top: 10px;
      display: flex;
      align-items: center;
      .copy-btn{
      }
    }
  }
  .update-emp-container{
    .arco-divider{
      margin-top: -10px;
      margin-bottom: 10px;
    }
    .account-container{
      .title{

      }
    }
  }
`;
