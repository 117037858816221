import styled from 'styled-components';
import { Layout } from '@arco-design/web-react';

const { Header } = Layout;

export const StyledHeader = styled(Header)`
  height: 50px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 8%);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: var(--color-bg-2);
  .dark-mode{
    margin-right: 10px;
  }
  .avatar-drop-down-wrap{
    padding-right: 24px;
    display: flex;
    align-items: center;
    .name{
      margin-left: 10px;
      color: rgb(var(--gray-10));
    }
  }
`;
