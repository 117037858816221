import { RequestOptions } from '@arco-design/web-react/es/Upload';
import { useRequest } from 'ahooks';
import { useCallback } from 'react';
import { getUploadToken, md5File, uploadFile } from './util';

const keyPrefix = 'new-admin-upload-prefix';
const urlPrefix = 'https://qnfile.personalstatement.cn/';

const useUpload = () => {
  const { runAsync: fetchUploadTokenAsync } = useRequest(getUploadToken, { manual: true });
  const { runAsync: uploadAsync } = useRequest(uploadFile, { manual: true });

  const customRequest = useCallback(async (options: RequestOptions) => {
    const { file, onSuccess } = options;
    const md5 = await md5File(file);
    const key = `${keyPrefix}/${md5}.${file.name.split('.').pop()}`;
    const token = await fetchUploadTokenAsync(key);
    await uploadAsync({ token, file, key });
    onSuccess({
      url: `${urlPrefix}${key}`
    });
  }, [fetchUploadTokenAsync, uploadAsync]);

  return {
    customRequest
  };
};

export default useUpload;
