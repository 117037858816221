export const oldPaths = {
  role: '/sys/role',
  jdgroup: '/content/jdgroup',
  jdproduct: '/product/jdproduct',
  jdproductclassify: '/product/jdproductclassify',
  jdproductservice: '/product/jdproductservice',
  jdproductlabel: '/product/jdproductlabel',
  menu: '/sys/menu',
  oss: '/oss/oss',
  config: '/sys/config',
  log: '/sys/log'
};

export const newPath = {
  root: '/',
  login: '/login',
  jdemployeecreation: '/employee/jdemployeecreation',
  schools: '/schools',
  majorInsight: '/majorInsight',
  refusal: '/refusal',
  course: '/course',
  major: '/content/jdproject',
  jdproductorder: '/product/jdproductorder',
  jdcollege: '/content/jdcollege',
  jdcountry: '/content/jdcountry',
  writingAdvise: '/content/writingAdvise',
  voluntaryManagement: '/product/jdproductorderuser',
  /** 仪表盘-工作台 */
  workplace: '/dashboard/workplace',
  _stastisticpannel: '/dashboard/_stastisticpannel',
  /** 学员管理-学员列表 */
  studentManage: '/person/user',
  studentDetail: '/student/detail',
  /** 选校方案 */
  chooseSchoolManage: '/chooseSchoolManage/list',
  /** 专业详情 */
  singleProjectDeatil: '/single-project-detail'
};

export const paths = {
  ...oldPaths,
  ...newPath
};

export const pathsNameMap = {
  [paths.root]: '奇点教育',
  [paths.majorInsight]: '咨询话术',
  [paths.refusal]: '拒录信息',
  [paths.course]: '课程'
};
