import { useRequest } from 'ahooks';
import { getMyVisibleProjectMaps } from './services';
import { useCallback, useEffect, useState } from 'react';
import { setMaps, useStoreDispatch } from '$store';
import { readMyVisibleProjectMaps, writeMyVisibleProjectMaps } from './myVisibleProjectMapStoarge';

const useProjectStoreLoader = ({ manual }: { manual?: boolean } = {}) => {
  const [userSeeLoading, setUserSeeLoading] = useState(false);

  const { data: myVisibleProjectMaps, loading: requestLoading, runAsync: runListMyVisibleProjects, cancel } = useRequest(getMyVisibleProjectMaps, { manual: true });
  const dispatch = useStoreDispatch();

  useEffect(() => {
    if (myVisibleProjectMaps) {
      writeMyVisibleProjectMaps(myVisibleProjectMaps);
      dispatch(setMaps(myVisibleProjectMaps));
    }
  }, [dispatch, myVisibleProjectMaps]);

  const reload = useCallback(async () => {
    const myVisibleProjectMaps = await readMyVisibleProjectMaps();
    if (!myVisibleProjectMaps) {
      setUserSeeLoading(true);
    } else {
      dispatch(setMaps(myVisibleProjectMaps));
    }
    cancel();
    await runListMyVisibleProjects();
    setUserSeeLoading(false);
  }, [cancel, dispatch, runListMyVisibleProjects]);

  useEffect(() => {
    !manual && reload();
  }, [manual, reload]);

  return {
    reload,
    loading: userSeeLoading,
    requestLoading
  };
};

export default useProjectStoreLoader;
