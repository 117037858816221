import { UserInfoContext } from '$context/UserInfoContext';
import { PermissionContext } from '$context/PermissionContext';
import useDarkMode from '$hooks/useDarkMode';
import { Avatar, Button, Dropdown, Input, Menu, Message, Modal } from '@arco-design/web-react';
import { useCallback, useContext, useEffect, useRef } from 'react';
import { IconSun, IconMoon, IconUser } from '@arco-design/web-react/icon';
import { StyledHeader } from './style';
import { changePassword } from './utils';
import { useRequest } from 'ahooks';
import Cookies from 'js-cookie';

const { useModal } = Modal;

const _Header = () => {
  const { userInfo, loadUserInfo } = useContext(UserInfoContext);
  const { loadPermissionList } = useContext(PermissionContext);
  const { name, figureurl } = userInfo;

  const [modal, contextHolder] = useModal();

  useEffect(() => {
    if (Object.keys(userInfo).length === 0) {
      void loadUserInfo();
    }
  }, [loadUserInfo, userInfo]);

  useEffect(() => {
    void loadPermissionList();
  }, [loadPermissionList]);

  const { isDarkMode, setIsDarkMode } = useDarkMode();
  const { runAsync: updatePassword } = useRequest(changePassword, { manual: true });

  const handleLogout = useCallback(() => {
    Cookies.remove('token', {
      domain: document.domain.split('.').slice(-2).join('.')
    });
    window.location.href = '/login';
  }, []);

  const newPasswordRef = useRef<string>('');

  const handleChangePassword = useCallback(() => {
    modal.confirm?.({
      title: '修改密码',
      content: <Input onChange={v => { newPasswordRef.current = v; }} type="password" placeholder='请输入新密码'/>,
      onOk: async () => {
        await updatePassword({
          password: newPasswordRef.current
        });
        Message.success('修改成功');
      }
    });
  }, [modal, updatePassword]);

  return <StyledHeader>
    {contextHolder}
    <div className="dark-mode">
      <Button
        icon={
          isDarkMode
            ? <IconMoon />
            : <IconSun />
        }
        onClick={() => setIsDarkMode(!isDarkMode)}
      />
    </div>
    <Dropdown
      position='bl'
      droplist={
        <Menu>
          <Menu.Item key='1' onClick={handleChangePassword}>修改密码</Menu.Item>
          <Menu.Item key='2' onClick={handleLogout}>退出登录</Menu.Item>
        </Menu>
      }
    >
      <div className="avatar-drop-down-wrap">
        {<Avatar>
          {
            figureurl
              ? <img alt="avatar" src={figureurl} />
              : <IconUser />
          }
        </Avatar>}
        <div className="name">{name}</div>
      </div>
    </Dropdown>
  </StyledHeader>;
};

export default _Header;
