import { Upload, UploadProps } from '@arco-design/web-react';
import { UploadItem } from '@arco-design/web-react/es/Upload';
import { useCallback, useEffect, useState } from 'react';
import useUpload from '$hooks/use-upload';

interface ImgUploadProps extends Omit<UploadProps, 'onChange'> {
  onChange?: (val?: string) => void
  value?: string
}

const ImgUpload = (props: ImgUploadProps) => {
  const { onChange, value, ...extra } = props;
  const [fileList, setFileList] = useState<UploadItem[]>();

  useEffect(() => {
    if (value) {
      setFileList([{
        url: value,
        uid: value
      }]);
    } else {
      setFileList([]);
    }
  }, [value]);

  useEffect(() => {
    if (fileList?.[0]) {
      const { url } = (fileList[0].response ?? {}) as any;
      url && onChange?.(url);
    }
  }, [fileList, onChange]);

  const handleRemove = useCallback(() => {
    onChange?.(undefined);
  }, [onChange]);

  const { customRequest } = useUpload();

  return <Upload
    imagePreview
    limit={1}
    fileList={fileList}
    onChange={setFileList}
    listType='picture-card'
    onRemove={handleRemove}
    customRequest={customRequest}
    {...extra}
  />;
};

export default ImgUpload;
