import { getMenu } from '$layout/service';
import { useRequest } from 'ahooks';
import { Menu } from '@arco-design/web-react';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { MenuItem as MenuItemType } from '$layout/type';
import { getDefaultSelectKeys, menuListToMenuMap } from './util';
import { useLocation, useNavigate } from 'react-router-dom';
import { PermissionContext } from '$context/PermissionContext';

const { SubMenu, Item: MenuItem } = Menu;

const menuListRenderer = (menuList: MenuItemType[], isAuth: any) => {
  if (menuList.length === 0) return null;

  return <>
    {
      menuList.map((menu, index) => {
        const { menuId, name, list } = menu;
        const key = menuId.toString();
        if (list.length) {
          return <SubMenu
            key={key}
            title={name}
          >
            {menuListRenderer(menu.list, isAuth)}
          </SubMenu>;
        }
        if (name === '订单列表') {
          if (!['product:order:query', 'product:order:user', 'product:order:project'].some(isAuth)) {
            return null;
          }
        }
        return <MenuItem key={key}>
          {name}
        </MenuItem>;
      })
    }
  </>;
};

const _Menu = () => {
  const navigate = useNavigate();
  const { data } = useRequest(getMenu);
  const { isAuth } = useContext(PermissionContext);

  const { menuList, menuMap, permissionList } = useMemo(() => {
    const menuList = data?.menuList ?? [];
    return {
      menuList,
      menuMap: menuListToMenuMap(data?.menuList ?? []),
      permissionList: data?.permissions
    };
  }, [data]);

  useEffect(() => {
    localStorage.setItem('permissionList', JSON.stringify(permissionList));
  }, [permissionList]);

  const location = useLocation();

  const [openKeys, setOpenKeys] = useState<number[]>([]);
  const [selectedKey, setSelectedKeys] = useState<string>();

  useEffect(() => {
    const { defaultOpenKeys, defaultSelectedKey } = getDefaultSelectKeys(menuList);
    setOpenKeys(defaultOpenKeys);
    setSelectedKeys(defaultSelectedKey);
  }, [menuList, location]);

  const handleClickMenuItem = useCallback((key: string) => {
    const menu = menuMap.get(Number(key));
    const isCurrentUrl =
      menu?.url === location.pathname ||
      `/${menu?.url}` === location.pathname;
    if (menu?.url && !isCurrentUrl) {
      navigate(menu.url);
    }
  }, [location.pathname, menuMap, navigate]);

  const handleMenuChange = useCallback((data: any) => {
    setOpenKeys([data]);
  }, []);

  return menuList.length
    ? <Menu
        openKeys={openKeys.map(String)}
        selectedKeys={selectedKey ? [selectedKey] : []}
        onClickMenuItem={handleClickMenuItem}
        onClickSubMenu={handleMenuChange}
      >
        {menuListRenderer(menuList, isAuth)}
      </Menu>
    : null;
};

export default _Menu;
