import { oldPaths } from '$const/paths';
import { MicroAppStruct } from './type';

export const microAppList = [
  process.env.APP_MICRO_LAGGACY_ADMIN_URL && {
    entry: process.env.APP_MICRO_LAGGACY_ADMIN_URL,
    name: 'micro-old-admin',
    activeRule: (location: Location) => {
      const shouldActive = Object.values(oldPaths).some(path => {
        return location.pathname === path;
      });
      return shouldActive;
    }
  }
].filter(Boolean) as MicroAppStruct[];

export const containerId = 'sub-app';
