import { Reducer, State } from '../interface';

const setMaps: Reducer<Partial<State>> = (state, { payload }) => {
  Object.entries(payload).forEach(([key, value]) => {
    // @ts-expect-error
    state[key] = value;
  });
};

export default setMaps;
