import { useEffect, useState } from 'react';

const useCountDown = (initialSeconds: number) => {
  const [seconds, setSeconds] = useState(initialSeconds);
  useEffect(() => {
    setTimeout(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);
  }, [seconds]);

  return {
    seconds,
    start: (s: number) => setSeconds(s)
  };
};

export default useCountDown;
