import { useCallback, useMemo, useState } from 'react';
import { Button, Divider, Form, Input, Message, Modal, Radio } from '@arco-design/web-react';
import FormItem from '@arco-design/web-react/es/Form/form-item';
import { useRequest } from 'ahooks';
import { checkPhone, createEmp, updateEmp } from './service';
import RoleSelector from './components/RoleSelector';
import { StyledAddEditModal } from './style';
import { isPhoneAvailable } from '$utils/validate';
import ImgUpload from '$components/img-upload';
import GroupSelector from './components/GroupSelector';
import Textarea from '@arco-design/web-react/es/Input/textarea';

const { useForm } = Form;

const useModifyEmp = ({ roleList, groupList, reloadList, onCancel }: { roleList?: any, groupList?: any, reloadList: () => void, onCancel?: () => void }) => {
  const [visible, setVisible] = useState(false);
  const { runAsync: runCreateEmp, loading: createLoading } = useRequest(createEmp, { manual: true });
  const { runAsync: runUpdateEmp, loading: updateLoading } = useRequest(updateEmp, { manual: true });
  const { runAsync: toCheckPhone } = useRequest(checkPhone, { manual: true });
  const [loginType, setLoginType] = useState('');
  const loading = useMemo(() => createLoading || updateLoading, [createLoading, updateLoading]);
  const [form] = useForm<any>();

  const showModifyEmpModal = useCallback((data?: any) => {
    if (data) {
      if (data.loginType && data.loginType === 'register') {
        setLoginType(data.loginType);
      }
      data.roleIdList = data.roleIdList ? data.roleIdList.split(',').map(Number) : [];
      data.groupIdList = data.groupIdList ? data.groupIdList.split(',').map(Number) : [];
      form.setFieldsValue(data);
    }
    setVisible(true);
  }, [form]);

  const handleOk = useCallback(async () => {
    try {
      await form.validate();
      const fieldData = form.getFieldsValue();
      const params = {
        ...fieldData,
        username: fieldData.name,
        roleIdList: fieldData.roleIdList ? fieldData.roleIdList.toString() : '',
        groupIdList: fieldData.groupIdList ? fieldData.groupIdList.toString() : ''
      };
      const checkRes = await toCheckPhone({ id: fieldData.id, phone: fieldData.phone });
      if (checkRes?.size > 0) {
        return Message.error('手机号重复');
      }
      if (!fieldData.id) {
        await runCreateEmp({ ...params });
        form.resetFields();
        Message.success('新增成功');
      } else {
        await runUpdateEmp({ ...params });
        form.resetFields();
        Message.success('修改成功');
      }
      reloadList();
      setVisible(false);
    } catch (e) {
      console.log(e);
    }
  }, [form, reloadList, runCreateEmp, runUpdateEmp, toCheckPhone]);

  const handleCopy = async () => {
    await navigator.clipboard.writeText('https://new-admin.personalstatement.cn');
    Message.success('复制成功');
  };

  const rules = {
    // 手机号校验
    phoneRules: [
      {
        required: true,
        validator: (value: any, callback: (error?: string) => void) => {
          if (!value) callback('请输入手机号');
          if (!isPhoneAvailable(value)) {
            callback('请输入正确格式的手机号');
          }
          callback();
        }
      }
    ]
  };

  const createEmpComp = useMemo(() => {
    return (
      <>
        <FormItem field="name" label='姓名' rules={[{ required: true, message: '请输入姓名' }]}>
          <Input placeholder='请输入姓名'/>
        </FormItem>
        <FormItem field="phone" label='手机号' rules={rules.phoneRules}>
          <Input placeholder='请输入手机号'/>
        </FormItem>
        <FormItem field="roleIdList" label='职能'>
          <RoleSelector roleList={roleList} type='addOrUpdate' />
        </FormItem>
        <FormItem field="sex" label='性别'>
          <Radio.Group>
            <Radio value={1}>男</Radio>
            <Radio value={2}>女</Radio>
          </Radio.Group>
        </FormItem>
        <FormItem label=' '>
          <div className="add-tips-link-container">
            <div className="add-emp-tips">注意：添加员工信息后需发送链接至员工端，让其完善信息</div>
            <div className="link">
              <span>注册链接：https://new-admin.personalstatement.cn</span>
              <Button
                className='copy-btn'
                type='text'
                status='success'
                onClick={handleCopy}
              >
                点击复制
              </Button>
            </div>
          </div>
        </FormItem>
      </>
    );
  }, [roleList, rules.phoneRules]);

  const updateEmpComp = useMemo(() => {
    return (
      <div className='update-emp-container'>
        <FormItem field="id" hidden>
          <Input />
        </FormItem>
        <div className="account-container">
          <Divider orientation='center'>账号信息</Divider>
          <FormItem field="phone" label='手机号' rules={rules.phoneRules}>
            <Input placeholder='请输入手机号' readOnly={!!loginType} />
          </FormItem>
          <FormItem field="weixin" label='微信号码'>
            <Input placeholder='请输入微信号码'/>
          </FormItem>
          <FormItem field="email" label='邮箱账户'>
            <Input placeholder='请输入邮箱账户'/>
          </FormItem>
          {
            !loginType &&
            <FormItem field="sex" label='性别'>
              <Radio.Group>
                <Radio value={1}>男</Radio>
                <Radio value={2}>女</Radio>
              </Radio.Group>
            </FormItem>
          }
        </div>
        {
          !loginType &&
          <>
            <div className="basic-info-container">
              <Divider orientation='center'>基本信息</Divider>
              <FormItem field="name" label='姓名' rules={[{ required: true, message: '请输入姓名' }]}>
                <Input placeholder='请输入姓名'/>
              </FormItem>
              <FormItem field="figureurl" label='头像'>
                <ImgUpload />
              </FormItem>
              <FormItem field="roleIdList" label='职能'>
                <RoleSelector roleList={roleList} type='addOrUpdate' />
              </FormItem>
            </div>

            <div className="show-container">
              <Divider orientation='center'>展示内容</Divider>
              <FormItem field="groupIdList" label='擅长学科'>
                <GroupSelector groupList={groupList} type='addOrUpdate' />
              </FormItem>
              <FormItem field="jobdescription" label='责任展示'>
                <Textarea placeholder='请输入责任展示'></Textarea>
              </FormItem>
              <FormItem field="shortdescription" label='介绍展示'>
                <Textarea placeholder='请输入介绍展示'></Textarea>
              </FormItem>
              <FormItem field="description" label='详情'>
                <Textarea placeholder='请输入详情'></Textarea>
              </FormItem>

            </div>
          </>
        }
      </div>
    );
  }, [groupList, loginType, roleList, rules.phoneRules]);

  const empModalMountPoint = useMemo(() => {
    return <Form
        form={form}
        autoComplete='off'
        wrapperCol={{
          span: 18
        }}
        labelCol={{
          span: 3
        }}
      >
        <Modal
          confirmLoading={loading}
          visible={visible}
          onCancel={() => { setVisible(false); form.resetFields(); onCancel?.(); }}
          title={form.getFieldValue('id') ? '修改员工信息' : '新增员工'}
          onOk={handleOk}
          style={{
            width: '50%'
          }}
        >
          <StyledAddEditModal>
            { form.getFieldValue('id') ? updateEmpComp : createEmpComp }
          </StyledAddEditModal>
        </Modal>
      </Form>;
  }, [createEmpComp, form, handleOk, loading, onCancel, updateEmpComp, visible]);
  return {
    showModifyEmpModal,
    empModalMountPoint
  };
};

export default useModifyEmp;
