import { PROJECT_STATUS } from '$const/projects';
import { GROUP_TYPE } from '$hooks/use-stastistic/const';
import { groupTypeOperationMap, AssignDocumentEmployeeOperationName } from './const';
import { ProjectType } from './interface';

export const ifProjectDocumentAndDelivery = (status: PROJECT_STATUS) => {
  return status >= PROJECT_STATUS.SCHOOL_SELECTION && status <= PROJECT_STATUS.REJECT;
};

export const ifProjectDocument = (status: PROJECT_STATUS) => {
  return status >= PROJECT_STATUS.ONLY_DOCUMENT_SCHOOL_SELECTION && status <= PROJECT_STATUS.ONLY_DOCUMENT_DELIVERED;
};

export const ifProjectDelivery = (status: PROJECT_STATUS) => {
  return status >= PROJECT_STATUS.ONLY_DELIVERY_SCHOOL_SELECTION;
};

export const ifProjectContainDocument = (status: PROJECT_STATUS) => {
  return ifProjectDocument(status) || ifProjectDocumentAndDelivery(status);
};

export const ifProjectContainDelivery = (status: PROJECT_STATUS) => {
  return ifProjectDelivery(status) || ifProjectDocumentAndDelivery(status);
};

export const parseOperationLog = (project: { operation_log: string }) => {
  const operationLog: Array<{
    operationTime: number
    operator: string
    operatorId: number
    statusStr: string
  }> = Array.isArray(project.operation_log) ? project.operation_log : JSON.parse(project.operation_log || '[]');
  return operationLog;
};

export const ifProjectExpire = (project: ProjectType) => {
  const { due_time } = project;
  if (due_time) {
    return new Date(due_time).getTime() - new Date().getTime() > 15 * 24 * 60 * 60 * 1000;
  }
};

export const ifProjectDocumentExpire = (project: ProjectType) => {
  const { due_time } = project;
  if (due_time) {
    const documentDueTime = new Date(due_time).getTime() - 7 * 60 * 60 * 1000;
    const setDocumentTime = getOperationTime(project, AssignDocumentEmployeeOperationName);
    if (setDocumentTime) {
      return documentDueTime > setDocumentTime.getTime();
    }
    return documentDueTime > new Date().getTime();
  }
};

export const ifProjectQAExpire = (project: ProjectType) => {
  const { due_time } = project;
  const operationLog = parseOperationLog(project);
  if (due_time) {
    const setQATime = operationLog?.find(o => o.statusStr === groupTypeOperationMap[GROUP_TYPE.QA])?.operationTime;
    if (setQATime) {
      return new Date(due_time).getTime() - setQATime;
    }
    return new Date(due_time).getTime() > new Date().getTime();
  }
};

export const getOperationTime = (project: ProjectType | any, statusStr: string) => {
  const operationLog = parseOperationLog(project);
  const operationTime = operationLog?.find(o => o.statusStr === statusStr)?.operationTime;
  return operationTime ? new Date(operationTime) : undefined;
};

export const ifAssignExpire = (project: ProjectType | any) => {
  const assignDocumentTime = getOperationTime(project, '文案/校友分配');
  if (assignDocumentTime) {
    const assignDocumentDate = new Date(assignDocumentTime);
    const isAM = assignDocumentDate.getHours() < 12;

    const TP1targetDate = new Date(assignDocumentTime);
    TP1targetDate.setDate(assignDocumentDate.getDate() + 1);
    TP1targetDate.setHours(12, 0, 0, 0);

    const TP2targetDate = new Date(assignDocumentTime);
    TP2targetDate.setDate(assignDocumentDate.getDate() + 1);
    TP2targetDate.setHours(23, 59, 59, 0);

    if (isAM) {
      if (assignDocumentTime.getTime() > TP1targetDate.getTime()) {
        return true;
      }
    } else {
      if (assignDocumentTime.getTime() > TP2targetDate.getTime()) {
        return true;
      }
    }
  }
  return false;
};
