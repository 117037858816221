import { PROJECT_STATUS, projectStatusNameMap } from '$const/projects';
import { Reducer, SimpleProjectType } from '../interface';

export const setProjectScaffold: Reducer<SimpleProjectType[]> = (state, action) => {
  state.projectMap = action.payload.reduce((acc, item) => {
    return Object.assign(acc, {
      [item.id]: {
        ...item,
        majorLoading: true,
        userLoading: true,
        employeeMapLoading: true,
        productLoading: true,
        orderLoading: true,
        attachmentsLoading: true
      }
    });
  }, {});
  state.statusCount = Object.keys(projectStatusNameMap).reduce((acc, nxt) => {
    const statusNum: PROJECT_STATUS = Number(nxt);
    return Object.assign(acc, {
      [statusNum]:
        action.payload.filter(item => item.project_status === statusNum).length
    });
  }, {});
};
