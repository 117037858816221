import { createSlice } from '@reduxjs/toolkit';
import { setProjectScaffold } from './reducers';
import { State } from './interface';
import { STASTISTIC } from './const';
import { setEmployeeMap } from './reducers/setEmployeeMap';
import setMaps from './reducers/setMaps';

const initialState: State = {
  projectMap: {},
  userMap: {},
  majorMap: {},
  productMap: {},
  orderMap: {},
  attachmentMap: {},
  employeeMap: {},

  statusCount: {},
  stastistic: Object.keys(STASTISTIC).reduce((cur, nxt) => {
    return Object.assign(cur, {
      [nxt]: 0
    });
  }, {}),
  userProjectMapMap: {},
  employeeProjectMapMap: {},
  documentEmployeeList: []
};

const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    setProjectScaffold,
    setEmployeeMap,
    setMaps
  }
});

export default projectsSlice;
