import { useCallback, useEffect, useMemo, useState } from 'react';
import { containerId } from './const';
import { loadMicroApps } from './util';
import { start } from 'qiankun';
import { IconLoading } from '@arco-design/web-react/icon';

const Mounter = (props: { id: string, loading?: boolean, onMount: () => void }) => {
  const { onMount, id, loading } = props;
  useEffect(() => {
    onMount();
  }, [onMount]);
  return <>
    {loading && <IconLoading /> }
    <div id={id}/>
  </>;
};

const useMicroApp = () => {
  const [loading, setLoading] = useState(false);

  const handleOnMount = useCallback(() => {
    loadMicroApps(setLoading);
    start();
  }, []);

  const mountPoint = useMemo(() => {
    return <Mounter
      id={containerId}
      loading={loading}
      onMount={handleOnMount}
    />;
  }, [handleOnMount, loading]);

  return {
    loading,
    mountPoint
  };
};

export default useMicroApp;
