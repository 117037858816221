import styled from 'styled-components';

export const StyledLayout = styled.div`
  height: 100%;
  .layout{
    height: 100%;
    .arco-layout-sider-children{
      display: flex;
      flex-direction: column;
      .arco-menu{
        flex: 1;
      }
    }
    .layout-header-content{
      background-color: var(--color-bg-1);
    }
    .layout-content-wrap{
      padding: 16px 0px;
    }
  }
  .logo{
    height: 50px;
    flex-shrink: 0;
    position: sticky;
    top: 0px;
    z-index: 100;
    background: linear-gradient(
      135deg,
      #08b493  0%,
      #3dc9a3  50%,
      #23af87 100% 
    );
    color: #fff;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
  }
`;
