import Layout from '$layout';
import store from '$store';
import { useEffect } from 'react';
import { Provider } from 'react-redux';

function App () {
  useEffect(() => {
    if (navigator.appVersion.includes('Win')) {
      document.documentElement.style.setProperty('--font-family', '微软雅黑, sans-serif');
    } else if (navigator.appVersion.includes('Mac')) {
      document.documentElement.style.setProperty('--font-family', 'PingFang SC');
    }
  }, []);
  return (
    <Provider store={store}>
      <Layout />
    </Provider>
  );
}

export default App;
