import { Button, Form, Input, Tabs, Message } from '@arco-design/web-react';
import { useRequest } from 'ahooks';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { StyledLogin } from './style';
import { apiGetCode, apiPhoneCodeLogin, checkIfLogin, getLoginQRCode, usernamePasswordLogin } from './utils';
import useCountDown from './useCountDown';
import { isPhoneAvailable } from '$utils/validate';
import useModifyEmp from '$pages/employee/jdemployee-creation/useModifyEmp';

const signature = Number(Math.random().toString().slice(-6));

const { TabPane } = Tabs;
const { Item: FormItem, useForm } = Form;

const Login = () => {
  // const location = useLocation();
  // const { loadUserInfo } = useContext(UserInfoContext);
  // useEffect(() => {
  //   const code = new URLSearchParams(location.search).get('code');
  //   if (code) {
  //     verifyNonce({ code });
  //   } else {
  //     feishuOAuth();
  //   }
  // }, [location.search, verifyNonce]);

  const { data: scanCodeLoginRes, cancel: cancelCheckIfLogin, run: startCheckIfLogin } = useRequest(() => checkIfLogin({ signature }), {
    pollingInterval: 2000,
    manual: true
  });
  const { data: qrCodeUrl } = useRequest(() => getLoginQRCode({ signature }));
  const { runAsync: runUsernamePasswordLogin } = useRequest(usernamePasswordLogin, { manual: true });
  const { runAsync: toSendSmsCode } = useRequest(apiGetCode, { manual: true });
  const { runAsync: toPhoneCodeLogin } = useRequest(apiPhoneCodeLogin, { manual: true });
  const [qrCodeVisiable, setQrCodeVisiable] = useState(true);

  useEffect(() => {
    if (qrCodeUrl) {
      startCheckIfLogin();
    }
  }, [qrCodeUrl, startCheckIfLogin]);

  const navigate = useNavigate();

  const finishLogin = useCallback((token: string, jump = true) => {
    const expireTime = new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000).toUTCString();
    document.cookie = `token=${token}; expires=${expireTime}; path=/`;
    localStorage.clear();
    if (jump) {
      navigate('/');
      cancelCheckIfLogin();
    }
  }, [cancelCheckIfLogin, navigate]);

  useEffect(() => {
    // 状态为13，代表用户在后台员工列表添加了相关信息，但从未登录过系统。需要弹窗让用户填写相关信息
    if (scanCodeLoginRes?.status === 13) {
      cancelCheckIfLogin();
      setQrCodeVisiable(false);
    } else {
      if (scanCodeLoginRes?.result && scanCodeLoginRes.token) {
        // set cookie
        finishLogin(scanCodeLoginRes.token);
      }
    }
  }, [cancelCheckIfLogin, finishLogin, scanCodeLoginRes]);

  const [form] = useForm();

  const handleLogin = useCallback(async () => {
    const res = await runUsernamePasswordLogin({
      ...form.getFieldsValue() as any
    });
    if (res.token) {
      finishLogin(res.token);
    }
  }, [finishLogin, form, runUsernamePasswordLogin]);

  const { seconds, start } = useCountDown(0);

  const getCode = async () => {
    const fieldObj = form.getFieldsValue();
    const phone = fieldObj.phone;
    const phoneAvailable = isPhoneAvailable(phone);
    if (!phoneAvailable) return Message.error('请输入正确的手机号码');
    const params = {
      mail: phone
    };
    await toSendSmsCode(params);
    start(60);
  };

  const { showModifyEmpModal, empModalMountPoint } = useModifyEmp({
    reloadList: () => { navigate('/'); },
    onCancel: () => { setQrCodeVisiable(true); }
  });

  const handlePhoneCodeLogin = async () => {
    const fieldObj = form.getFieldsValue();
    const params = {
      phone: fieldObj.phone,
      code: fieldObj.code,
      unionId: scanCodeLoginRes?.unionId ?? ''
    };
    const res: any = await toPhoneCodeLogin(params);
    console.log('res: ', res);
    if (res && res.code === 0) {
      if (res.status === 12 || res.status === 14) {
        Message.error(res.msg);
      } else {
        finishLogin(res.token, false);
        showModifyEmpModal({ id: res.userId, phone: fieldObj.phone, loginType: 'register' });
      }
    }
  };

  return <StyledLogin>
    {empModalMountPoint}
    <div className="page-container">
      <div className='left'>
        奇点留学后台管理系统
      </div>
      <div className='right'>
        <Tabs className="tabs">
          <TabPane key={1} title="账号密码登录">
            <Form form={form} wrapperCol={{ span: 24 }}>
              <FormItem field="username">
                <Input placeholder='请输入用户名'/>
              </FormItem>
              <FormItem field="password">
                <Input type="password" placeholder='请输入密码'/>
              </FormItem>
            </Form>
            <Button onClick={handleLogin} type='primary'>登录</Button>
          </TabPane>
          <TabPane key={2} title="二维码登录">
            {
              qrCodeVisiable
                ? <img className='qr-code' src={qrCodeUrl}/>
                : <>
              <Form form={form} wrapperCol={{ span: 24 }}>
                <FormItem field="phone">
                  <Input placeholder='请输入手机号码'/>
                </FormItem>
                <FormItem field="code">
                  <Input
                    placeholder='请输入验证码'
                    suffix={
                      seconds > 0
                        ? <Button type='text' disabled>{`${seconds}秒后重新获取`}</Button>
                        : <Button type='text' onClick={getCode}>获取验证码</Button>
                    }
                  />
                </FormItem>
              </Form>
              <Button onClick={handlePhoneCodeLogin} type='primary'>登录</Button>
              </>
            }
          </TabPane>
        </Tabs>
      </div>
    </div>
  </StyledLogin>;
};

export default Login;
