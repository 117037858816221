import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import '@arco-design/web-react/dist/css/arco.min.css';

const rootElement = document.getElementById('root');

const root = rootElement !== null
  ? ReactDOM.createRoot(
    rootElement
  )
  : undefined;

root?.render(<App />);
