import { lazy, useMemo } from 'react';
import { paths } from '$const/paths';

const JdemployeeCreation = lazy(
  async () => await import(/* webpackChunkName: "employee" */ '$pages/employee/jdemployee-creation')
);

const Schools = lazy(
  async () => await import(/* webpackChunkName: "schools" */ '$pages/schools')
);

const MajorInsight = lazy(
  async () => await import(/* webpackChunkName: "refine-major" */ '$pages/major-insight')
);

const Refusal = lazy(
  async () => await import(/* webpackChunkName: "refusal" */ '$pages/refusal')
);

const Course = lazy(
  async () => await import(/* webpackChunkName: "course" */ '$pages/course')
);

const Major = lazy(
  async () => await import(/* webpackChunkName: "major" */ '$pages/major')
);

const Order = lazy(
  async () => await import(/* webpackChunkName: "order" */ '$pages/order')
);

const College = lazy(
  async () => await import(/* webpackChunkName: "college" */ '$pages/college')
);

const Country = lazy(
  async () => await import(/* webpackChunkName: "country" */ '$pages/country')
);

const Product = lazy(
  async () => await import(/* webpackChunkName: "product" */ '$pages/product')
);

const VoluntaryManagement = lazy(
  async () => await import(/* webpackChunkName: "voluntaryManagement" */ '$pages/voluntary-management')
);

const Workplace = lazy(
  async () => await import(/* webpackChunkName: "workplace" */ '$pages/dashboard/workplace')
);
const _StastisticPannel = lazy(
  async () => await import(/* webpackChunkName: "workplace" */ '$pages/dashboard/_stastistic-pannel')
);

const StudentManage = lazy(
  async () => await import(/* webpackChunkName: "studentManage" */ '$pages/student-manage')
);

const StudentDetail = lazy(
  async () => await import(/* webpackChunkName: "studentDetail" */ '$pages/student-manage/StudentDetail')
);

const ChooseSchoolManage = lazy(
  async () => await import(/* webpackChunkName: "studentDetail" */ '$pages/choose-school-manage')
);

const WritingAdvise = lazy(
  async () => await import(/* webpackChunkName: "writingAdvise" */ '$pages/writing-advise')
);

const SingleProjectDeatil = lazy(
  async () => await import(/* webpackChunkName: "studentDetail" */ '$pages/single-project-detail')
);

const useRouters = () => {
  const routers = useMemo(() => {
    return [
      { path: paths.jdemployeecreation, element: <JdemployeeCreation /> },
      { path: paths.schools, element: <Schools /> },
      { path: paths.majorInsight, element: <MajorInsight /> },
      { path: paths.refusal, element: <Refusal /> },
      { path: paths.course, element: <Course /> },
      { path: paths.major, element: <Major /> },
      { path: paths.jdproductorder, element: <Order /> },
      { path: paths.jdcollege, element: <College /> },
      { path: paths.jdcountry, element: <Country /> },
      { path: '/product-temp', element: <Product /> },
      { path: paths.voluntaryManagement, element: <VoluntaryManagement /> },
      { path: paths.workplace, element: <Workplace /> },
      { path: paths._stastisticpannel, element: <_StastisticPannel /> },
      { path: paths.studentManage, element: <StudentManage /> },
      { path: paths.studentDetail, element: <StudentDetail /> },
      { path: paths.chooseSchoolManage, element: <ChooseSchoolManage /> },
      { path: paths.writingAdvise, element: <WritingAdvise /> },
      { path: paths.singleProjectDeatil, element: <SingleProjectDeatil /> }
    ];
  }, []);

  return routers;
};

export default useRouters;
