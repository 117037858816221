import request from '$services/request';
import { apiv2 } from '$services/url';
import { SimpleProjectType, State } from '$store/projects-slice/interface';
import { jd_employee_creation, jd_product_order, jd_product_order_employee, jd_product_order_project, jd_product_service_snapshot, jd_product_snapshot, jd_user, sys_oss, v2_major } from '@prisma/client';

export const listProjects = (data: { skip: number, take: number }) => {
  return request<{ list: jd_product_order_project[], total: number }>({
    url: apiv2.listProjects,
    data
  });
};

export const mapEmployeeDetail = (data: { employeeIds: number[] }) => {
  return request<Record<number, jd_employee_creation & { role_id: number }>>({
    url: apiv2.mapEmployeeDetail,
    data
  });
};

export const mapUserDetail = (data: { userIds: number[] }) => {
  return request<Record<number, jd_user>>({
    url: apiv2.mapUserDetail,
    data
  });
};

export const mapMajorDetail = (data: { majorIds: number[] }) => {
  return request<Record<number, v2_major>>({
    url: apiv2.mapMajorDetail,
    data
  });
};

export const mapProductDetail = (data: { productIds: number[] }) => {
  return request<Record<number, jd_product_snapshot>>({
    url: apiv2.mapProductDetail,
    data
  });
};

export const mapOrderDetail = (data: { orderIds: number[] }) => {
  return request<Record<number, jd_product_order & { services: jd_product_service_snapshot[] }>>({
    url: apiv2.mapOrderDetail,
    data
  });
};

export const mapAttachmentsDetail = (data: { attachmentIds: number[] }) => {
  return request<Record<number, sys_oss>>({
    url: apiv2.mapAttachmentsDetail,
    data
  });
};

type ProjectEmployeeStruct = Partial<jd_product_order_employee>;

export const listSimple = () => {
  return request<{
    projectList: SimpleProjectType[]
    projectEmployeeList: ProjectEmployeeStruct[]
  }>({
    url: apiv2.listSimple
  });
};

export const getMyVisibleProjectMaps = () => {
  return request<Partial<State>>({
    url: apiv2.getMyVisibleProjectMaps,
    method: 'GET'
  });
};
