import { QINIU_UPLOAD_ACTION } from '$const';
import request from '$services/request';
import { apiv2 } from '$services/url';
import SparkMD5 from 'spark-md5';

export const getUploadToken = (fileKey: string) => {
  return request<string>({
    url: apiv2.getUploadToken,
    method: 'GET',
    params: {
      fileKey
    }
  });
};

export const formatObjectToFormData = (param?: Object) => {
  const formData = new FormData();
  Object.keys(param ?? {}).forEach((key) => {
    // @ts-expect-error
    const value = param[key];
    formData.append(key, value);
  });
  return formData;
};

export const uploadFile = (data: { file: File, key: string, token: string }) => {
  return request<any>({
    url: QINIU_UPLOAD_ACTION,
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data',
      token: false
    },
    withCredentials: false,
    data: formatObjectToFormData(data)
  });
};

export const md5File = async (file: File) => await new Promise<string>(resolve => {
  const fileReader = new FileReader();
  fileReader.readAsBinaryString(file);
  fileReader.onload = e => {
    const md5 = SparkMD5.hashBinary(e.target?.result as string);
    resolve(md5);
  };
});
